export default {
    // 导航栏
    nav: {
        home: "HomePage",
        functiona: "Function",
        introduce: "Platform Introduction",
        msg: "News & Information",
        wx: "MINI PROGRAM",
    },
    hov: {
        Scanning: "Scan to Explore",
        test: "Big Data Galore",
    },
    proportion: {
        title: "",
        moneycar: "",
        trucks: "",
        carriage: "Coach & Bus",
        proportion: "",
    },
    carousel: {
        functions: "FUNCTION",
        search: "Price Search",
        line: "——",
        car: "New commercial vehicle",
        num: "Full coverage of over 1.40 million kinds of models of commercial vehicles.",
        configuration: "Core configuration of the vehicle",
        price: "Guide price from manufacturers & Recent transaction price",
        dataServices: "Scan and experience big data services",
        vehicleIndustry: "Data engine for commercial vehicle industry",
    },
    carousel1: {
        search: "Historical sales volumes search",
        num: "Quick search of data for 70 million vehicles over the past 20 years.",
        configuration:
            "Multi- dimensional sales data analysis from manufacturer, region, type, etc.",
        price: "Monthly data update & hunting the latest market trends timely",
    },

    //
    //
    carousel2: {
        car: "sharing platform of industry blacklist",
        search: "Corporate information Search",
        num: "Nearly 1 million + commercial vehicle industry",
        configuration: "Main body information enterprise industrial and commercial information, exclusive industry information, traffic violations and industry reward and punishment information",
    },
    carousel3: {
        search: "Quick valuation for ",
        line: "——",
        car: "Used Commercial Vehicle",
        num: "Online quick valuation for used commercial vehicles",
        configuration: "Online operation",
        price: "Driving license Scan",
    },
    carousel4: {
        car: "Commercial Vehicle Financial Mortgage and Pledge Information Sharing Platform",
        search: "Vehicle Registration Status Search",
        num: "Effectively reducing financial industry risks and addressing issues related to mortgage processing and inquiries.",
    },
    footer: {
        Partner: "PARTNER",
        company: "Company: Big Data Platform For Commercial Vehicle Industry",
        Contact: "Contact : 010-5338 8968",
        mail: "E-mail: service@chinaucv.com",
        address:
            "Address: Courtyard 72, West Third Ring North Road, Haidian District, Beijing",
        Postcode: "Postcode: 100037",
        WeChat: "WeChat Program",
        Scanning: "Scanning to experience",
        link: "links: China Automobile Dealers Association",
        car: "copyright 2021 @ BIG DATA Platform FOR COMMERCIAL VEHICLE industry",
    },
    center: {
        title: sessionStorage.getItem("englishTitle"),
        vehicle: "Commercial vehicle",
        van: "Truck",
        bus: "Bus",
    },
};
